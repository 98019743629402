export const setPerfChartOptions = props => {
  // MARK: Increase X Points
  const increaseXPoints = [];
  increaseXPoints.push({
    id: `increase`,
    type: "flags",
    customType: "increasePoint",
    name: "",
    color: "#FFD5A3",
    fillColor: "#FFD5A3",
    legendIndex: 0,
    showInLegend: false,
    shape: "circlepin",
    y: -10, // flag pin length
    data: [],
    tooltip: {
      headerFormat: "", // hide de x (date) value
      // pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: ${convertToCurrency(
      //   depositAmount
      // )} SEK`,
      pointFormat: `<span style="color:{series.color}"><b>{point.title}</b></span>: {point.amount} %`,
      valueDecimals: 0
    },
    style: {
      width: "100%",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  });

  // MARK: Deposit Flags
  const depositFlags = [];
  if (props.mainChartHasData && props.hasDeposits) {
    depositFlags.push({
      id: `d_flag`,
      type: "flags",
      customType: "movementFlag",
      name: "Insättningar",
      color: "#FFD5A3",
      fillColor: "#FFD5A3",
      legendIndex: 0,
      // showInLegend: true,
      showInLegend: false,
      legendSymbol: "rectangle",
      customLegendName: "Ins/uttag",
      y: -30, // flag pin length
      data: [],
      tooltip: {
        headerFormat: "", // hide de x (date) value
        // pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: ${convertToCurrency(
        //   depositAmount
        // )} SEK`,
        pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.amount} SEK`,
        valueDecimals: 2
      },
      style: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    });
  }

  // MARK: Withdraws Flags
  const withdrawsFlags = [];
  if (props.mainChartHasData && props.hasWithdraws) {
    withdrawsFlags.push({
      id: `w_flag`,
      type: "flags",
      customType: "movementFlag",
      name: "Uttag",
      color: "#FFD5A3",
      fillColor: "#FFD5A3",
      legendIndex: 0,
      // showInLegend: Object.keys(movements.deposits).length < 1,
      showInLegend: false,
      legendSymbol: "rectangle",
      customLegendName: "Ins/uttag",
      y: -35, // flag pin length
      data: [],
      tooltip: {
        headerFormat: "", // hide de x (date) value
        // pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: ${convertToCurrency(
        //   withdrawAmount
        // )} SEK`,
        pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.amount} SEK`,
        valueDecimals: 2
      },
      style: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    });
  }

  const hasFlags = depositFlags.length > 0 || withdrawsFlags.length > 0;

  return {
    credits: {
      enabled: false
    },
    // MARK: Chart
    chart: {
      height: 400,
      events: {
        load: props.onChartLoad,
        render: props.onRender
      },
      position: {
        y: -15
      }
      // animation: false
    },

    scrollbar: {
      enabled: false
    },

    // MARK: X Axis
    xAxis: {
      type: "datetime",
      // labels: {
      //   format: "{value:%d-%m-%Y}"
      // },
      events: {
        afterSetExtremes: props.afterSetExtremes
      },
      minRange: 3600 * 1000 * 24 // (one day) minRange affects rangeSelector buttons, per example month will be disabled if minRange > 1 month, see https://api.highcharts.com/highstock/xAxis.minRange
    },

    // MARK: Y Axis
    yAxis: [
      {
        opposite: false, // Highstock sets the Y axis placement to opposite:true by default !!
        minRange: 20,
        // Comment/Uncomment below if we want to show the benchmark chart as the default chart
        // ...(!props.hasSpecialAccounts &&
        //   !props.showBenchmarkChart && {
        //     height: "75%"
        //   })
        ...(!props.hasSpecialAccounts && {
          height: "75%"
        }),
        ...(props.showBenchmarkChart && {
          height: "80%",
          min: 0
        })

        // ...(!props.hasSpecialAccounts && {
        //   height: "75%"
        // })
        // max: yAxisMax,
        // startOnTick: false,
        // tickInterval: 15,
        // title: {
        //   text: "Avkastning  (%)"
        // }
      },
      ...(!props.hasSpecialAccounts
        ? [
            {
              title: {
                text: ""
              },
              labels: {
                enabled: false
              },
              minRange: 1000,
              top: "75%",
              height: "25%",
              ...(props.showBenchmarkChart && {
                top: "80%",
                height: "20%"
              }),
              offset: 0,
              lineWidth: 0
            }
          ]
        : [])
    ],

    // MARK: Series
    series: !props.showBenchmarkChart
      ? [
          {
            // lineWidth: 3,
            id: "account",
            name: "Er utveckling",
            color: "#4885B2",
            // legendIndex: 1,
            showInLegend: false,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          ...(!props.hasSpecialAccounts
            ? [
                {
                  id: "marketValue",
                  type: "column",
                  name: "Marknadsvärde",
                  color: "#FFD5A3",
                  showInLegend: false,
                  data: [],
                  yAxis: 1,
                  dataGrouping: {
                    enabled: false
                  },
                  tooltip: {
                    pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} SEK`,
                    valueDecimals: 0
                  }
                }
              ]
            : []),
          ...depositFlags,
          ...withdrawsFlags
        ]
      : [
          {
            // lineWidth: 3,
            id: "account",
            name: "Er utveckling",
            color: "#4885B2",
            showInLegend: true,
            legendIndex: 2,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          {
            id: "marketValue",
            type: "column",
            name: "Marknadsvärde",
            color: "#FFD5A3",
            showInLegend: false,
            data: [],
            yAxis: 1,
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} SEK`,
              valueDecimals: 0
            }
          },
          {
            id: "benchmark",
            name: "Modellportföljens utveckling",
            color: "#FFD5A3",
            showInLegend: true,
            legendIndex: 3,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          {
            id: "hybrid",
            name: "Utveckling",
            color: "#B5651D",
            showInLegend: true,
            legendIndex: 1,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:#B5651D">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            },
            zones: [],
            zoneAxis: "x" // Use x-axis for the zones
          },
          {
            id: "flag",
            type: "flags",
            name: "Er portföljstart",
            showInLegend: false,
            y: -55, // flag pin length
            data: [
              {
                x: 0,
                y: 0,
                title: "Er portföljstart"
              }
            ],
            tooltip: {
              valueDecimals: 2
            },
            zIndex: 1000
            // fillColor: "rgba(255, 255, 255, 0)" // Make transparent
            // shape: "squarepin"
          },
          ...increaseXPoints,
          ...depositFlags,
          ...withdrawsFlags
        ],

    tooltip: {
      xDateFormat: "%Y-%m-%d"
    },

    // MARK: Range Selector
    rangeSelector: {
      dropdown: props.isLargeDesktop ? "responsive" : "always",
      labelStyle: {
        display: "none"
      },
      buttonPosition: {
        align: "right",
        x: 0,
        y: 0
      },
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 1,
        width: 40,
        r: 1, // button border radius
        style: {
          color: "#4885B2",
          borderRadius: 0
        },
        states: {
          hover: {
            fill: "none",
            style: {
              fontWeight: "bold"
            }
          },
          select: {
            fill: "none",
            style: {
              color: "#2E457E",
              fontWeight: "bold"
            }
          }
          // disabled: { ... }
        }
      },
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1 mån",
          title: "1 mån",
          events: {
            click: function () {
              props.setSelectedRange(0);
            }
          }
        },
        {
          type: "month",
          count: 3,
          text: "3 mån",
          title: "3 mån",
          events: {
            click: function () {
              props.setSelectedRange(1);
            }
          }
        },
        {
          type: "month",
          count: 6,
          text: "6 mån",
          title: "6 mån",
          events: {
            click: function () {
              props.setSelectedRange(2);
            }
          }
        },
        {
          type: "year",
          count: 1,
          text: "1 år",
          title: "1 år",
          events: {
            click: function () {
              props.setSelectedRange(3);
            }
          }
        },
        {
          type: "all",
          text: "Allt",
          title: "Allt",
          events: {
            click: function () {
              props.setSelectedRange(4);
            }
          }
        },
        {
          type: "ytd",
          text: "YTD",
          title: "YTD",
          events: {
            click: function () {
              props.setSelectedRange(5);
            }
          },

          preserveDataGrouping: true
        }
      ],
      inputEnabled: false, // it supports only days
      selected: 4 // index of which button will be selected at start i.e. YTD
    },

    // MARK: Navigator
    navigator: {
      adaptToUpdatedData: false, // stops looping and disabling range selector buttons
      handles: { enabled: props.isDesktop },
      height: props.isDesktop ? 40 : 0,
      margin: props.isDesktop ? 25 : 0,
      maskInside: props.isDesktop,
      outlineWidth: props.isDesktop ? 1 : 0,
      series: {
        data: [],
        color: "#4885B2",
        fillOpacity: 0.3
      },
      xAxis: {
        visible: props.isDesktop
      },
      yAxis: {
        visible: props.isDesktop,
        minRange: 20
      }
    },

    // MARK: Legend
    legend:
      props.showBenchmarkChart || hasFlags
        ? {
            enabled: true,
            symbolRadius: 0,
            borderRadius: 0,
            borderWidth: 0,
            floating: false,
            align: "right",
            verticalAlign: "top",
            layout: "horizontal",
            itemStyle: {
              color: "#262626"
            },
            itemHoverStyle: {
              color: "#808080"
            },
            itemHiddenStyle: {
              // Legend colour when not active/selected
              color: "#262626"
              // color: "#cccccc"
            },
            labelFormatter: function () {
              return this.userOptions.customLegendName || this.name;
            }
          }
        : {},

    // MARK: Plot Options
    plotOptions: {
      series: {
        // stacking: "normal"
        events: {
          legendItemClick: props.onLegendItemClick
        }
      }
    }
  };
};
