import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

const ReturnsChart = props => {
  const [state, setState] = useState({ loaded: false });
  const { data } = props;
  let AggrRebateAmountFactor = 0;
  let totalDays = 0;
  let totalYears = 0;
  let totalPercentage = 0;
  let yearlyYieldPercentage = 0;

  // Set parsedData as transData with added AggrRebateAmount
  const parsedData =
    data.transData && data.transData.length > 0
      ? data.transData.map(item => {
          const rebates = data.rebatesTransactions.filter(
            rebate => rebate.Date <= item.Date
          );
          const AggrRebateAmount = rebates.reduce(
            (acc, rebate) => acc + Math.abs(rebate.TotalAmountBase),
            0
          );
          return { ...item, AggrRebateAmount };
        })
      : [];

  if (parsedData.length > 0) {
    // Add Yield calculation to parsedData
    parsedData.forEach(item => {
      item.Yield =
        (item.FeeWithoutRebate - item.FeePaid) / item.AggrRebateAmount + 1;
    });

    // Calculate YearlyYieldPercentage
    // See Christian's oneDrive spreadsheet for more details
    parsedData.forEach((item, index) => {
      if (index === 0) {
        AggrRebateAmountFactor = item.Yield;
      } else {
        AggrRebateAmountFactor = AggrRebateAmountFactor * item.Yield;
      }
      totalPercentage += (item.Earnings / data.feeInvestment) * 100;
    });

    const oldestDate = parsedData[parsedData.length - 1].Date;
    totalDays = oldestDate
      ? Math.abs((new Date() - new Date(oldestDate)) / (1000 * 60 * 60 * 24))
      : 0;
    totalYears = totalDays / 365;

    yearlyYieldPercentage =
      (Math.pow(AggrRebateAmountFactor, 1 / totalYears) - 1) * 100;
  }

  const options = useRef({
    credits: {
      enabled: false
    },
    chart: {
      type: "column"
    },
    title: {
      align: "center",
      text: "Rabattens avkastning",
      style: {
        fontFamily: "Roboto",
        fontSize: "0.8rem",
        fontWeight: "bold"
      }
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: "category"
      // labels: {
      //   formatter: function () {
      //     return "Rabattens avkastning";
      //   }
      // }
    },
    yAxis: {
      title: {
        text: ""
      }
      // labels: {
      //   formatter: function () {
      //     return this.value + "%";
      //   }
      // }
      // min: 0,
      // max: data < 100 ? 100 : data + 10
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: "",
      formatter: function () {
        if (this.point.name === "Totalt") {
          return (
            "<b>" +
            this.point.name +
            "</b>: " +
            totalPercentage.toFixed(2) +
            "%"
          );
        } else if (this.point.name === "Årlig avkastning") {
          return (
            "<b>" +
            this.point.name +
            "</b>: " +
            yearlyYieldPercentage.toFixed(2) +
            "%"
          );
        }
      }
    },
    series: [
      {
        name: "Rabattens avkastning",
        colorByPoint: true,
        data:
          data.transData && data.transData.length > 0
            ? [
                {
                  name: "Totalt",
                  y: Number(totalPercentage.toFixed(2)),
                  color: "#4885B2"
                },
                {
                  name: "Årlig avkastning",
                  y: Number(yearlyYieldPercentage.toFixed(2)),
                  color: "#FFD5A3"
                }
              ]
            : []
      }
    ],
    scrollbar: {
      enabled: false
    }
  });

  useEffect(() => {
    if (!state.loaded) {
      setState({ loaded: true });
    }
  }, [state.loaded, data]);

  return !state.loaded ? (
    <CircularProgress />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      options={options.current}
      containerProps={{
        style: {
          height: "300px",
          position: "relative"
        }
      }}
    />
  );
};

export default ReturnsChart;

ReturnsChart.propTypes = {
  data: PropTypes.object,
  benchmarkChartData: PropTypes.array,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
