import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { convertToCurrency } from "../../../../helpers/functions";

const SavingsChart = props => {
  const [state, setState] = useState({ loaded: false });
  const { data } = props;

  const feeInvestmentData = [];
  const feeTotalSavedData = [];

  if (data.transData.length > 0) {
    data.rebatesTransactions.forEach(item => {
      feeInvestmentData.push({
        name: item.Date,
        x: 0,
        y: Math.abs(item.TotalAmountBase)
      });
    });

    data.transData.forEach(item => {
      feeTotalSavedData.push({
        name: item.Date,
        x: 1,
        y: Number(item.Earnings)
      });
    });
  }

  const options = useRef({
    credits: {
      enabled: false
    },
    chart: {
      type: "column"
    },
    title: {
      align: "center",
      text: "Besparing över tid",
      style: {
        fontFamily: "Roboto",
        fontSize: "0.8rem",
        fontWeight: "bold"
      }
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      // type: "category",
      categories: ["Din investering", "Besparing"]
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        formatter: function () {
          return convertToCurrency(this.value);
        }
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: "normal"
        // dataLabels: {
        //   enabled: true,
        //   format: "{point.percentage:.0f}%"
        // }
      },
      series: {
        borderWidth: 0
        // dataLabels: {
        //   enabled: true,
        //   format: "{point.y:.1f}%"
        // }
      }
    },

    tooltip: {
      headerFormat: "",
      formatter: function () {
        return (
          "<b>" + this.point.name + "</b>: " + convertToCurrency(this.point.y)
        );
      }
    },

    series: [
      {
        name: "Besparing",
        colorByPoint: true,
        data: feeTotalSavedData
      },
      {
        name: "Din investering",
        colorByPoint: false,
        data: feeInvestmentData,
        color: "#4885B2"
      }
    ],

    scrollbar: {
      enabled: false
    }
  });

  useEffect(() => {
    if (!state.loaded) {
      setState({ loaded: true });
    }
  }, [state.loaded, data]);

  return !state.loaded ? (
    <CircularProgress />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      options={options.current}
      containerProps={{
        style: {
          height: "300px",
          position: "relative"
        }
      }}
    />
  );
};

export default SavingsChart;

SavingsChart.propTypes = {
  data: PropTypes.object,
  benchmarkChartData: PropTypes.array,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
