import { useState, useEffect } from "react";

import useAxios from "../../../hooks/useAxios";
import { roundNumber } from "../../../helpers/functions";

import useErrorHandler from "../../../hooks/useErrorHandler";

const useFeesModal = props => {
  const { open, toggle, data } = props;
  const { initAxios } = useAxios();
  const errorHandler = useErrorHandler();

  const [state, setState] = useState({
    loaded: false,
    serverMessage: "",
    feeInvestment: data.FeeInvestment ? Number(data.FeeInvestment) : 0,
    rebatesTransactions: data.RebatesTransactions,
    transData: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { axiosInstance } = initAxios("private");
        const response = await axiosInstance.get(
          `/account/fees/${data.AccountId}`
        );

        const transData = response.data;
        transData.forEach(fee => {
          // we convert to number type for table sorting to work correctly
          fee.FeePaid = Number(fee.FeePaid);
          fee.FeeWithoutRebate = Number(fee.FeeWithoutRebate);
          fee.Earnings = Number(fee.FeeWithoutRebate - fee.FeePaid);
        });

        // calculate feeTotalSaved
        let feeTotalSaved = 0;
        transData.forEach(fee => {
          feeTotalSaved = feeTotalSaved + fee.Earnings;
        });

        //order transData by fee.Date descending
        transData.sort((a, b) => new Date(b.Date) - new Date(a.Date));

        setState(prevState => ({
          ...prevState,
          loaded: true,
          feeTotalSaved: Number(feeTotalSaved),
          discountReturn: feeTotalSaved
            ? roundNumber((feeTotalSaved / state.feeInvestment) * 100, 2)
            : 0,
          transData
        }));
      } catch (error) {
        console.log(error, "error");
        errorHandler.serverError(error);
        setState(prevState => ({
          ...prevState,
          loaded: true,
          serverMessage: error.message
        }));
      }
    };

    if (open && !state.loaded) {
      fetchData();
    }
  }, [
    data.AccountId,
    errorHandler,
    initAxios,
    open,
    state.feeInvestment,
    state.loaded
  ]);

  return {
    open,
    toggle,
    state
  };
};

export default useFeesModal;
