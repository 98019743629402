import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import { format } from "date-fns";

import DashboardPaper from "../../theme/styled/DashboardPaper";

// import useSummary from "./useSummary";
import useResponsive from "../../hooks/useResponsive";
import PerformanceChart from "./PerformanceChart";
import TransactionsChart from "./TransactionsChart";
import HoldingsTable from "./HoldingsTable";
import FeesModal from "./FeesModal";

import { convertToCurrency } from "../../helpers/functions";
import { DashboardConsumer } from "../../contexts/DashboardProvider";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}));

const Summary = props => {
  const isDesktop = useResponsive("up", "md");
  const dashboardCtxt = DashboardConsumer();
  const isAccount = location.pathname.includes("/dashboard/depaer/");

  const { summaryData, accountDetails } = props;
  const { AccountId, BenchmarkId, ModelPortfolioId, Fee, IsSpecial } =
    accountDetails || {};

  const {
    chartData,
    benchmarkChartData,
    transactionsChartData,
    firstTransactionDate,
    movements,
    // summaryHoldings,
    totalMarketValue,
    hideDeposit,
    totalDeposits,
    totalReturns,
    positionsAndRebates,
    holdings,
    holdsTtlAvgAmntDirtyBase,
    highWaterMark
  } = isAccount ? summaryData : dashboardCtxt.state;

  const [feesModalOpen, setFeesModalOpen] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = feesModalOpen ? "hidden" : "auto";
  }, [feesModalOpen]);

  return (
    <DashboardPaper>
      <Box
        sx={{
          display: "flex",
          flexFlow: { xs: "column", lg: "row" }
        }}
      >
        <Box sx={{ width: { lg: "60%" } }}>
          <Typography
            variant="h6"
            align="left"
            sx={{
              position: "relative",
              width: "30%",
              mb: 0.63,
              zIndex: 2
            }}
          >
            Utveckling
          </Typography>
          {!isAccount || (ModelPortfolioId && IsSpecial === "0") ? (
            <PerformanceChart
              chartData={chartData}
              benchmarkChartData={benchmarkChartData}
              firstTransactionDate={firstTransactionDate}
              movements={movements}
              accountId={AccountId}
              benchmarkId={BenchmarkId}
            />
          ) : (
            <TransactionsChart chartData={transactionsChartData} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: { lg: "40%" },
            ml: { md: 2, lg: 3 }
          }}
        >
          <Box>
            <Typography
              variant="h6"
              align="left"
              sx={{
                mb: 2.5
              }}
            >
              Översikt
            </Typography>
            {/* <Typography align="left" variant="body2" color="common.black">
                Likvider
                <span style={{ float: "right" }}>
                  {(totalCurrencyValue &&
                    convertToCurrency(totalCurrencyValue)) ||
                    0}{" "}
                  SEK
                </span>
              </Typography>

              {summaryHoldings.map((holding, key) => {
                if (
                  holding.instrumentType !== "Currency" &&
                  holding.instrumentType !== "TotalMarketValue" &&
                  holding.instrumentType !== "TotalReturns" &&
                  holding.value !== 0
                ) {
                  return (
                    <Typography
                      key={key}
                      align="left"
                      variant="body2"
                      color="common.black"
                    >
                      {holding.instrumentTypeLabel}
                      <span style={{ float: "right" }}>
                        {(holding.value && convertToCurrency(holding.value)) || 0}{" "}
                        SEK
                      </span>
                    </Typography>
                  );
                }
              })} */}

            <Typography
              align="left"
              variant="body2"
              color="common.black"
              component="p"
              // sx={{ mt: 0, borderTop: 1 }}
            >
              <b>{totalMarketValue.instrumentTypeLabel}</b>
              <Typography
                variant="body2"
                sx={{ float: "right" }}
                component="span"
              >
                <b>
                  {(totalMarketValue.value &&
                    convertToCurrency(totalMarketValue.value)) ||
                    0}{" "}
                  SEK
                </b>
              </Typography>
            </Typography>
          </Box>
          {!hideDeposit && (
            <Fragment>
              <Box sx={{ mt: { xs: 2, md: 3 } }}>
                <Typography align="left" variant="body2" color="common.black">
                  Nettoinsättningar{" "}
                  <HtmlTooltip
                    disableFocusListener
                    placement={isDesktop ? "bottom" : "top"}
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="body2">
                          Denna summa redovisar nettoinsättningar, det vill säga
                          dina insättningar minus eventuella uttag från depån.
                          Eventuell erlagd förvaltningsrabatt räknas som uttag.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon fontSize="inherit" />
                  </HtmlTooltip>
                  <span style={{ float: "right" }}>
                    {convertToCurrency(
                      totalDeposits + positionsAndRebates.rebates
                    )}{" "}
                    SEK
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography align="left" variant="body2" color="common.black">
                  Totala inflyttade tillgångar
                  <span style={{ float: "right" }}>
                    {positionsAndRebates.positions &&
                      convertToCurrency(positionsAndRebates.positions)}{" "}
                    SEK
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography align="left" variant="body2" color="common.black">
                  <b>
                    {totalReturns.instrumentTypeLabel}{" "}
                    <HtmlTooltip
                      disableFocusListener
                      placement={isDesktop ? "bottom" : "top"}
                      title={
                        <React.Fragment>
                          <Typography color="inherit" variant="body2">
                            Det här är den totala värdeutvecklingen du fått på
                            alla dina innehav oavsett när du investerat. Om du
                            gjort flera insättningar/uttag under årens lopp kan
                            denna siffra inte användas för att beräkna din
                            effektiva årliga avkastning.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon fontSize="inherit" />
                    </HtmlTooltip>
                    <span style={{ float: "right" }}>
                      {totalReturns.value &&
                        convertToCurrency(totalReturns.value)}{" "}
                      SEK
                    </span>
                  </b>
                </Typography>
              </Box>
              {isAccount && Fee && Fee !== "0.00" && Fee !== "33.00" && (
                <>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      align="left"
                      variant="body2"
                      color="common.black"
                      borderTop={1}
                      sx={{ pt: 2 }}
                    >
                      <b>Vinstdelning</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="left"
                      variant="body2"
                      color="common.black"
                    >
                      Standardnivå vinstdelning
                      <span style={{ float: "right" }}>33%</span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="left"
                      variant="body2"
                      color="common.black"
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                      }}
                    >
                      <span
                        style={{
                          width: "65%",
                          textAlign: "left"
                        }}
                      >
                        Erlagd rabatt för sänkning av vinstdelning{" "}
                        <HtmlTooltip
                          disableFocusListener
                          placement={isDesktop ? "bottom" : "top"}
                          title={
                            <React.Fragment>
                              <Typography color="inherit" variant="body2">
                                Denna kostnad skall ses över ett
                                flerårsperspektiv eftersom den ökar din
                                avkastning under flera år. Posten bör ses som en
                                amortering under lägst en 5 års period då vi
                                anser att ingen skall placera med en kortare
                                tidshorisont.
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <InfoIcon fontSize="inherit" />
                        </HtmlTooltip>
                      </span>
                      <span
                        style={{
                          width: "35%",
                          textAlign: "right"
                        }}
                      >
                        {positionsAndRebates.rebates &&
                          convertToCurrency(positionsAndRebates.rebates)}{" "}
                        SEK
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="left"
                      variant="body2"
                      color="common.black"
                    >
                      Vinstdelning{" "}
                      <HtmlTooltip
                        disableFocusListener
                        placement={isDesktop ? "bottom" : "top"}
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">
                              {/* Condition below is never triggered. Overriden by CAR-101 */}
                              {Fee && Fee !== "0.00"
                                ? "Vinstdelning talar om hur stor andel av värdet som skapas som vi erhåller som ersättning för vår förvaltningstjänst, vilket vi gör istället för att ha en fast avgift. På så vis har vi alltid linjerade intressen! Om vinstdelningen är 10% betyder det att du behåller 90% av vinsten."
                                : "Beräkning av vinstdelning sker på kvartalsbasis. När din portfölj har varit investerad över ett kvartalsskifte kommer portföljens vinstdelning samt högvattenmärke att presenteras här."}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon fontSize="inherit" />
                      </HtmlTooltip>
                      <span style={{ float: "right" }}>
                        {Fee ? Fee.split(".")[0] + "%" : "-"}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      align="left"
                      variant="body2"
                      color="common.black"
                    >
                      Din del av vinsten
                      <span style={{ float: "right" }}>
                        {Fee ? 100 - Fee.split(".")[0] + "%" : "-"}
                      </span>
                    </Typography>
                  </Box>
                </>
              )}
              {isAccount && highWaterMark && (
                <Box sx={{ mt: 1 }}>
                  <Typography
                    align="left"
                    variant="body2"
                    color="common.black"
                    borderTop={1}
                    sx={{ py: 1 }}
                  >
                    Högvattenmärke{" "}
                    {format(new Date(highWaterMark.Date), "yyyy-MM-dd")}{" "}
                    <HtmlTooltip
                      disableFocusListener
                      placement={isDesktop ? "bottom" : "top"}
                      title={
                        <React.Fragment>
                          {/* <Typography color="inherit" variant="body2">
                        Det Högsta värdet för din depå{" "}
                        <span style={{ color: "green" }}>plus</span>{" "}
                        insättningar,{" "}
                        <span style={{ color: "red" }}>minus</span> avgifter,
                        skatt och uttag per redovisat datum.
                      </Typography> */}
                          <Typography color="inherit" variant="body2">
                            Högvattenmärket är det högsta värdet för din depå.
                            Värdet beräknas som den högsta punkten i en period
                            och regleras genom att periodens insättningar
                            adderas och periodens avgifter, skatter samt uttag
                            dras bort.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon fontSize="inherit" />
                    </HtmlTooltip>
                    <span style={{ float: "right" }}>
                      {highWaterMark.HighWatermark &&
                        convertToCurrency(highWaterMark.HighWatermark)}{" "}
                      SEK
                    </span>
                  </Typography>
                </Box>
              )}
              {isAccount && positionsAndRebates.rebates && (
                <Box sx={{ mt: 1 }}>
                  <Link
                    onClick={() => setFeesModalOpen(!feesModalOpen)}
                    sx={{
                      display: "block",
                      width: "fit-content",
                      color: "primary.lighter",
                      p: 0,
                      textTransform: "none",
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="primary.lighter"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      Se hur mycket värde din erlagda rabatt skapat
                    </Typography>
                  </Link>
                </Box>
              )}
            </Fragment>
          )}
        </Box>
      </Box>
      {isAccount && (
        <Box sx={{ mt: { xs: 2, md: 3 } }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2
            }}
          >
            Innehav
          </Typography>
          <HoldingsTable
            holdings={holdings}
            holdingsTotalMarketValue={totalMarketValue.value}
            holdsTtlAvgAmntDirtyBase={holdsTtlAvgAmntDirtyBase}
          />
        </Box>
      )}
      {feesModalOpen && (
        <FeesModal
          open={feesModalOpen}
          toggle={() => setFeesModalOpen(!feesModalOpen)}
          data={{
            AccountId,
            FeeInvestment: Math.abs(positionsAndRebates.rebates) || 0,
            RebatesTransactions: positionsAndRebates.rebatesTransactions || []
          }}
        />
      )}
    </DashboardPaper>
  );
};

Summary.propTypes = {
  summaryData: PropTypes.object,
  accountDetails: PropTypes.object
};

export default Summary;
